<template>
  <div class="main clearfix">
    <div
      style=" text-align:center; overflow: auto;width: 500px;margin: 0 auto;"
    >
      <iframe
        :src="authorize"
        height="404"
        frameBorder="0"
        scrolling="false"
      />
  </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";


import axios from "@/api/apis";

export default {
  name: "Login",
  created() {},
  setup() {
    const state = reactive({
      authorize: ""
    });
    const loginWechatAuthorize = () => {
      axios
        // .post("/account/login/wechat/authorize")
        .post("https://cqgdrm.com/api/website/account/login/wechat/authorize")
        .then(function(data) {
          console.log("====", data);
          state.authorize = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    };

    onMounted(() => {
      loginWechatAuthorize();
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style scoped>
</style>
